import { Component, OnInit, Input } from '@angular/core';
import { ArticuloService } from '../../../services/articulo.service';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';
import { GlobalConstants } from 'src/app/models/global-constants';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = ''; // Default Logo
  @Input() newsletter: boolean = true; // Default True

  companiaInfo: any = {};
  today: number = Date.now();
  clases: any[] = [];
  articulos: any[] = [];
  idCia_SHA1: string = '';
  id_dashboard_tipo: number = null;
  sessionInfo: any = {};

  constructor(private router: Router,
    private spinnerService: SpinnerService) {
    this.idCia_SHA1 = localStorage.getItem("id_SHA");
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
    this.clases = JSON.parse(localStorage.getItem("menuFooter"));
    this.companiaInfo = JSON.parse(localStorage.getItem("companiaInfo"));

    if (this.companiaInfo != null) {
      this.themeLogo = `${GlobalConstants.urlService}/companias/view/image/${this.companiaInfo.id_imagen_logo}`;
    }
  }

  ngOnInit(): void {
  }

  onClickSoporte() {
    window.open(`https://soporte.grupotsiperu.com.pe:8444/grupotsi-erp-web/login`, '_blank')
  }

  onClickIntranet() {
    window.open(`https://facturacion.grupotsiperu.com.pe:8445/grupotsi-web/login`, '_blank')
  }

  onClickFacturacionElectronica() {
    window.open(`https://facturacion.grupotsiperu.com.pe:8445/grupotsi-web/consultas?empresa=${this.companiaInfo.razon}`, '_blank')
  }

  onClickFacturacionElectronicaOracle() {
    window.open(`https://web.grupotsiperu.com.pe:8449/#/consulta-facturador?empresa=${this.companiaInfo.razon}`, '_blank')
  }

  onClickRouterNosotros() {
    this.router.navigateByUrl(`/ecommerce/pages/nosotros`);
  }

  onClickRouterReclamaciones() {
    this.router.navigateByUrl(`/ecommerce/pages/libro-reclamaciones`);
  }


  onClickAbrirTsiOraclePrueba() {
    window.open(`https://testing.grupotsiperu.com.pe:8843/#/dashboard`, '_blank')
  }

  onClickAbrirPoliticaPrivacidad() {
    this.router.navigateByUrl(`/ecommerce/pages/privacy`);
  }

  onClickFiltroCodigos(data: any) {
    localStorage.setItem("busqueda", 'footer');
    this.spinnerService.activarLoading(true);
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Inferior' } }));
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Inferior' } });
    }
  }
}